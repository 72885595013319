import React from 'react';

import { cls } from '../../frontend-shared/helpers';

import './SectionGradient.css';

const SectionGradient = ({ color, invert, position }) => (
  <div className={cls('SectionGradient', { color, invert, position, })} />
);

export default SectionGradient;
