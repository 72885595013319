import React from 'react';

import { PrismicRichText } from '@prismicio/react';

import { Divider, FormButton } from '../../frontend-shared/components';

import Link from './Link';

const DividerContentBlock = ({ entries = [], shadow = false }) => (
  <div className="w-full px-4 lg:px-8 xl:px-0 flex flex-col gap-24 md:gap-32 pt-16 md:pt-24 pb-16">
    {entries.map(({ title, content, image, button_text, button_link }) => {
      const button = button_text && (
        <div className="flex items-center justify-center w-full pt-8">
          <Link className="no-underline" href={button_link}>
            <FormButton color="secondary">
              {button_text}
            </FormButton>
          </Link>
        </div>
      );
      const matter = (
        <PrismicRichText
          field={content.richText || content}
          components={{
            paragraph: ({ children }) => (
              <p className="text-blue sm:text-xl text-lg mb-2">
                {children}
              </p>
            ),
          }}
        />
      );
      return (
        <div className={`grid md:grid-cols-2 grid-cols-1 md:gap-6 items-start md:mb-12 mb-16`} key={title}>
          <div className="xl:pr-28">
            <h3 className="text-blue lg:text-5xl md:text-4xl text-3xl font-medium md:mb-8 mb-6 tracking-tighter">
              {title}
            </h3>
            <div className="hidden md:block">
              {matter}
              {button}
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-6">
            <Divider className="md:!hidden" color="primary" orientation="horizontal" weight="bold" />
            <Divider className="!hidden md:!flex" color="primary" orientation="vertical" weight="bold" />
            <img
              alt={title}
              loading="lazy"
              className={`rounded-2xl${shadow ? ' shadow-xl' : ''} m-0`}
              src={image?.fluid?.srcWebp}
              srcset={image?.fluid?.srcSetWebp}
              height={image?.dimensions?.height}
              width={image?.dimensions?.width}
            />
          </div>
          <div className="block md:hidden pt-6 w-full">
            {matter}
            {button}
          </div>
        </div>
      );
    })}
  </div>
);

export default DividerContentBlock;
