import React from 'react';

import { cls } from '../../frontend-shared/helpers';

const Card = ({ className, children, ...rest }) => (
  <div
    className={cls('Card', {}, ['bg-white py-8 px-5 shadow-lg rounded-3xl', className].filter(Boolean).join(' '))}
    {...rest}
  >
    {children}
  </div>
);

export default Card;
