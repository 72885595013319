import React from 'react';
import { graphql } from 'gatsby';
import { PrismicRichText } from '@prismicio/react';

import { FormButton } from '../../frontend-shared/components';
import Link from '../Elements/Link';

const radialCenter = 'absolute left-1/2 top-[400px] sm:top-72 md:top-80 lg:top-[400px] xl:top-[440px]';

const offset = (pos) => `calc(-50% + ${Number(pos || 0) * 0.75}vw + ${Number(pos || 0) * 5}px)`;
const sizing = (scale, factor = 1) => `calc(${Number(scale || 1) * factor * 0.625}vw + ${Number(scale || 1) * 40}px)`;

const Integrations = ({
  slice: {
    primary: {
      background_1,
      background_2,
      button_link,
      button_text,
      title: { richText: title },
    },
    items,
  },
}) => (
  <div className="bg-blue w-full p-16 lg:p-32 relative overflow-clip h-[200vw] sm:h-[120vw] lg:h-[90vw] xl:h-[80vw]">
    <img
      className={`aspect-square ${radialCenter} min-w-[700px]`}
      src={background_1?.fluid?.srcWebp}
      srcset={background_1?.fluid?.srcSetWebp}
      style={{ transform: 'translate(-50%, -50%)' }}
    />
    <img
      className={`aspect-square ${radialCenter} min-w-[700px]`}
      src={background_2?.fluid?.srcWebp}
      srcset={background_2?.fluid?.srcSetWebp}
      style={{ transform: 'translate(-55%, -30%)' }}
    />
    <div
      className={`bg-radial aspect-square h-full md:h-auto md:w-full ${radialCenter}`}
      style={{ transform: 'translate(-50%, -50%)' }}
    />
    <div className="w-full z-10 relative flex flex-col items-center gap-10 fade-to-sides">
      <PrismicRichText
        field={title}
        components={{
          paragraph: ({ children }) => (
            <h2 className="font-medium text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-white text-center">
              {children}
            </h2>
          ),
        }}
      />
      {button_text && (
        <Link className="no-underline" href={button_link}>
          <FormButton color="primary">
            {button_text}
          </FormButton>
        </Link>
      )}
    </div>
    {items?.map(({
      integration: {
        document: {
          data: {
            name,
            logo_light: logo,
          },
        },
      },
      bubble_style,
      pos_x,
      pos_y,
      scale,
      scale_image,
    }) => (
      <div
        className={`${radialCenter} rounded-full flex flex-col items-center justify-center`}
        key={name}
        style={{
          background: bubble_style ? 'radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 40%, #00063ba0 95%)' : '#00063b',
          transform: `translate(${offset(pos_x)}, ${offset(pos_y)})`,
          height: sizing(scale, 6),
          width: sizing(scale, 6),
        }}
      >
        <img
          className="m-0"
          alt={name}
          src={logo?.fluid?.srcWebp}
          srcset={logo?.fluid?.srcSetWebp}
          style={{ width: `${Number(scale_image || 1) * 75}%` }}
        />
      </div>
    ))}
  </div>
);

export default Integrations;

export const query = graphql`
  fragment HomeIntegrationsSlice on PrismicHomepageDataBodyIntegrations {
    id
    slice_type
    primary {
      background_1 {
        fluid(maxHeight: 3600, maxWidth: 3600) {
          srcWebp
          srcSetWebp
        }
      }
      background_2 {
        fluid(maxHeight: 3600, maxWidth: 3600) {
          srcWebp
          srcSetWebp
        }
      }
      button_link
      button_text
      title { richText }
    }
    items {
      integration {
        document {
          ... on PrismicIntegration {
            id
            data {
              name
              logo_light {
                fluid(maxHeight: 360, maxWidth: 360) {
                  srcWebp
                  srcSetWebp
                }
              }
            }
          }
        }
      }
      bubble_style
      pos_x
      pos_y
      scale
      scale_image
    }
  }
`
