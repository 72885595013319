import HeroSection from "./HeroSection"
import WhyCarriyo from "./WhyCarriyo"
import TrustedPartners from "./TrustedPartners"
import EmpowerSection from "./EmpowerSection"
import BoostYourEcommerce from './BoostYourEcommerce';
import Integrations from "./Integrations";
import HomeTestimonial from "./Testimonials"

export const components = {
  hero: HeroSection,
  why_carriyo: WhyCarriyo,
  trusted_partners: TrustedPartners,
  empower_your_business: EmpowerSection,
  boost_your_ecommerce: BoostYourEcommerce,
  integrations: Integrations,
  testimonials: HomeTestimonial,
}
