import React from "react";
import { graphql } from "gatsby";

import { SliceZone } from "@prismicio/react"
import { components } from "../components/Home"
import SEO from "../components/seo"
import Layout from "../components/layout"

const Homepage = (props) => {
  const { data } = props
  if (!data) return null  
  const pageData = data.prismicHomepage
  const page = pageData.data || {}
  return (
    <Layout className="background1">
      <SEO
        context={props.pageContext}
        cpath={props.location.pathname}
      />
      <SliceZone
         slices={page.body}
         components={components}
         context={pageData}
         defaultComponent={() => null}
      />    
    </Layout>
  );
};

export default Homepage

export const query = graphql`
  query homepageQuery{
    prismicHomepage {
    id
    type
    data {
      features {
        icon {
          alt
          dimensions {
            width
            height
          }
          fluid {
            srcWebp
            srcSetWebp
          }
        }
        description {
          text
        }
        link
      }
      body{
          ... on PrismicSliceType {
            slice_type
          }
          ...HomeHeroSlice
          ...WhyCarriyoSlice
          ...HomePageTrustedPartnersSlice
          ...EmpowerSlice
          ...BoostYourEcommerceSlice
          ...HomeIntegrationsSlice
          ...HomeTestimonialSlice
      }
    }
  }
  }
`
