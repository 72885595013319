import React, { useState } from 'react';
import { graphql } from "gatsby"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import 'react-tabs/style/react-tabs.css';

import Card from '../Elements/Card';
import Link from '../Elements/Link';
import SectionGradient from '../Elements/SectionGradient';

const Heading = ({ children }) => (
  <h2 className="text-blue text-lg uppercase font-medium border-b border-solid border-blue-900 pb-2 mb-12 tracking-wider">
    {children}
  </h2>
);

const WhyCarriyo = ({
  context: {
    data: {
      features,
    },
  },
  slice: {
    primary: {
      feature_heading,
      heading,
    },
    items,
  },
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <div className="relative">
      <div className="h-10">
        <SectionGradient color="white" position="top" />
      </div>
      <div className="max-w-7xl mx-auto z-30 relative px-4 z-10">
        <Card className="text-center w-full">
          <Heading>{feature_heading?.text}</Heading>
          <div className="flex flex-wrap lg:flex-nowrap justify-items-center gap-16 lg:gap-x-0 justify-center lg:justify-between text-center">
            {(features || []).map(({ description, icon, link }) => {
              const content = (
                <div className="flex flex-col gap-4 align-center items-center h-32 w-32 p-4">
                  <img
                    className="w-8 lg:w-10 m-0"
                    alt={icon.alt}
                    src={icon.fluid.srcWebp}
                    srcSet={icon.fluid.srcSetWebp}
                  />
                  <div className="text-blue text-sm lg:text-md">
                    {description?.text}
                  </div>
                </div>
              );
              return link ? (
                <Link className="no-underline rounded-xl hover:bg-[#f8f8f8]" href={link}>
                  {content}
                </Link>
              ) : content;
            })}
          </div>
        </Card>
      </div>
      <div className="max-w-7xl mx-auto pt-10 z-30 relative px-4 z-10">
        <div className="md:hidden block">
          <div className="">
            <Heading>{heading?.text}</Heading>
            <div className="grid grid-cols-1">
              <ul className="m-0 p-0 links">
                {items && items.map((item, index) => (
                  <li className="pb-8 mb-8 relative" key={index}>
                    <div className="flex gap-2">
                      <div className="w-6 h-6 mt-3">
                        <StaticImage src="../../assets/images/spheres.png" alt="" width={18} height={18} className="list-icon" />
                      </div>
                      <h3 className="lg:text-3xl text-2xl text-blue font-medium tracking-tight mb-4">{item.feature_text}</h3>
                    </div>
                    <div className="text-center">
                      <GatsbyImage image={getImage(item.feature_image)} alt={item.feature_text} width="334" height="260" className="mt-5 shadow-xl rounded-2xl" />
                    </div>
                  </li>
                ))}
              </ul>
              <div />
            </div>
          </div>
        </div>
        <Card className="mb-16 md:mb-0 lg:px-14 border-b-8 border-solid border-red flex-col gap-8 md:flex hidden">
          <Heading>{heading?.text}</Heading>
          <Tabs
            className="gap-10 grid md:grid-cols-2 grid-cols-1"
            selectedIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
          >
            <div className="whycarriyolist">
              <TabList>
                {items && items.map((item, index)=>(
                  <Tab key={index}>
                    <div className="w-6 h-6 mt-5">
                      <StaticImage src="../../assets/images/spheres.png" alt="" width="15" height="15" />
                    </div>
                    {item.feature_text}
                  </Tab>
                ))}
              </TabList>
            </div>
            <div>
              {items && items.map((item, index) => (
                <TabPanel>
                  <GatsbyImage image={getImage(item.feature_image)} alt={item.feature_text}  width="614" height="479" className="shadow-xl rounded-2xl" />
                </TabPanel>
              ))}
            </div>
          </Tabs>
          <div />
        </Card>
      </div>
    </div>
  );
};

export default WhyCarriyo;

export const query = graphql`
  fragment WhyCarriyoSlice on PrismicHomepageDataBodyWhyCarriyo {
    id
    slice_type
    primary {
      heading {
        text
        html
      }
      feature_heading {
        text
      }
    }
    items {
      feature_text
      feature_image {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      button_text
      button_link
    }
  }
`
