import * as React from "react"

import Card from './Card';

const FeaturesList = ({ items }) => (
  <div className="gap-5 grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1">
    {items?.map(({ feature_count, feature_text }) => (
      <Card className="text-center">
        <h2 className="xl:text-6xl text-5xl font-medium text-blue mb-3 tracking-tighter">{feature_count.text}</h2>
        <p className="font1 text-blue text-base font-medium m-0">{feature_text}</p>
      </Card>
    ))}
  </div>
);

export default FeaturesList
