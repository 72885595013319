import * as React from "react"
import { graphql } from "gatsby"

import LinearContentBlock from '../Elements/LinearContentBlock';
import StripeSection from '../Elements/StripeSection';

const EmpowerSection = ({
  slice: {
    primary: { heading },
    items,
  },
}) => (
  <div className="relative z-20 pt-24">
    <div className="bg7 shipment1 absolute right-0 top-80 -z-10" />
    <div
      className="bg8 hidden lg:block -right-28 top-[2500px] absolute -z-10"
      style={{ transform: 'rotate(20deg)' }}
    />
    <StripeSection title={heading?.text}>
      <LinearContentBlock
        entries={items?.map(({ feature_heading, description, feature_image, ...rest }) => ({
          title: feature_heading?.text,
          content: description?.richText,
          image: feature_image,
          ...rest,
        }))}
        orient="twist"
      />
    </StripeSection>
  </div>
);

export default EmpowerSection;

// gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 550, srcSetMinWidth: 400)
export const query = graphql`
  fragment EmpowerSlice on PrismicHomepageDataBodyEmpowerYourBusiness {
    id
    slice_type
    primary {
      heading {
        text
        html
      }
    }
    items {
      feature_heading {
        text
        html
      }
      button_text
      button_link
      description {
        richText
      }
      feature_image {
        dimensions {
          width
          height
        }
        fluid {
          srcSetWebp
          srcWebp
        }
      }
    }
  }
`
