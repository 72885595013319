import * as React from "react"
import { graphql } from "gatsby"

import DividerContentBlock from '../Elements/DividerContentBlock';
import StripeSection from '../Elements/StripeSection';

const BoostYourEcommerce = ({
  slice: {
    primary: { heading },
    items,
  },
}) => (
  <div className="relative z-20 pt-24">
    <div
      className="bg8 hidden lg:block left-24 top-[720px] absolute -z-10"
      style={{ transform: 'translateX(-50%) scale(-0.75, 0.75) rotate(30deg)' }}
    />
    <div
      className="bg8 hidden lg:block -right-48 top-[1280px] absolute -z-10"
      style={{ transform: 'translateX(-50%) scale(-0.5, 0.5) rotate(10deg)' }}
    />
    <StripeSection title={heading?.text}>
      <DividerContentBlock entries={items} />
    </StripeSection>
  </div>
);

export default BoostYourEcommerce;

export const query = graphql`
  fragment BoostYourEcommerceSlice on PrismicHomepageDataBodyBoostYourEcommerce {
    id
    slice_type
    primary {
      heading {
        text
        html
      }
    }
    items {
      title
      button_text
      button_link
      content {
        richText
      }
      image {
        dimensions {
          width
          height
        }
        fluid {
          srcSetWebp
          srcWebp
        }
      }
    }
  }
`
