import * as React from "react"
import { graphql } from "gatsby"

import TrustedBrands from "../Elements/TrustedBrands";

const TrustedPartners = ({
  slice: {
    primary: {
      heading,
      button_link,
      button_text,
    },
  },
}) => (
  <TrustedBrands
    button_link={button_link}
    button_text={button_text}
    className="pb-40 sm:pb-20 pt-40 md:pt-24 md:pt-64 -mt-40 z-10"
    title={heading?.text}
  />
);

export default TrustedPartners;

export const query = graphql`
  fragment HomePageTrustedPartnersSlice on PrismicHomepageDataBodyTrustedPartners {
    id
    slice_type
    primary {
      heading {
        text
        html
      }
      button_link
      button_text
    }
  }
`
