import React from "react"
import { graphql, useStaticQuery } from 'gatsby';
import { PrismicRichText } from '@prismicio/react';

const OutOfTheBoxContent = ({ className }) => {
  const { carriers, siteSettings: { data: site } } = useStaticQuery(graphql`
    query {
      siteSettings : prismicSitesettings {
        data {
          out_of_the_box_content { richText }
        }
      }
      carriers : allPrismicIntegration(
        filter: { data: { rank: { ne: null }, type: { eq: "carrier" } } }
        sort: { fields: data___rank, order: DESC }
        limit: 6
      ) {
        edges {
          node {
            uid
            data {
              name
              logo : logo_dark {
                dimensions {
                  width
                  height
                }
                fluid {
                  srcSetWebp
                  srcWebp
                }
              }
            }
          }
        }
      }
    }
  `);
  return (
   <div className={['max-w-7xl mx-auto px-4', className].filter(Boolean).join(' ')}>
     <div className="background1 md:py-16 py-8 lg:px-14 md:px-8 px-4 -mt-20 md:mb-12 relative z-30 md:shadow-2xl shadow rounded-2xl">
       <div className="flex lg:flex-row flex-col items-center">
         <div className="lg:w-5/12 px-4">
           <PrismicRichText
             field={site.out_of_the_box_content.richText}
             components={{
               heading2: ({ children }) => <h2 className="text-blue tracking-tighter text-8xl font-medium mb-4">{children}</h2>,
               paragraph: ({ children }) => <p className="text-blue md:text-xl text-lg">{children}</p>,
             }}
           />
         </div>
         <div className="lg:w-7/12 px-4 xl:pl-8">
           <div className="grid md:grid-cols-3 grid-cols-2 md:gap-16 gap-8 justify-center items-center text-center lg:mt-0 mt-4">
             {carriers.edges.map(({ node: { data: { logo, name } } }) => (
               <div className="flex justify-center content-center" key={logo.alt}>
                 <img
                   alt={logo.alt || name}
                   loading="lazy"
                   src={logo?.fluid?.srcWebp}
                   srcSet={logo?.fluid?.srcSetWebp}
                   width={Math.round(logo?.dimensions?.width / 2)}
                   height={Math.round(logo?.dimensions?.height / 2)}
                 />
               </div>
             ))}
           </div>
         </div>
       </div>
     </div>
    </div>
  );
};

export default OutOfTheBoxContent;
